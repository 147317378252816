<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  padding: 8px;
  box-sizing: border-box;
}
</style>
